/* Margins */
.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

/* Border */
.border-none {
  border: none !important;
}

.radius {
  border-radius: 8px;
}

/* Align */
.d-flex {
  display: flex;
}

.flex-d-row {
  display: flex;
  flex-direction: row;
}

.flex-d-column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.text-align {
  text-align: center;
}
