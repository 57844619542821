.adjustment {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  font-size: 1.4rem;
  margin-bottom: 0rem;
  font-weight: 100;
  text-align: center;
  color: #ffffff;
}

@media screen and (max-width: 600px) {
  .footer {
    font-size: 1.2rem;
  }

  .top-footer {
    padding: 2rem;
  }

  .a {
    font-size: 12px;
    margin-top: 15px;
  }
}

@media screen and (max-width: 380px) {
  .footer {
    font-size: 1.2rem;
  }

  .top-footer {
    padding: 2rem;
  }

  .a {
    font-size: 10px;
    margin-top: 15px;
  }
}
