.logo {
  display: none;
}

.menu-section .header {
  position: absolute;
  top: 0;
  padding-top: 30px;
}

@media (max-width: 1199px) {
  .nav {
    display: none;
  }

  .BurgerMenu__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;

    i {
      background:#172b4d;
      width: 32px;
      height: 4px;
      margin: 4px;
      border-radius: 2px;
      transition: all ease 0.5s;
    }

    .open:nth-child(1) {
      transform: rotate(45deg) translate(9px, 8px);
    }

    .open:nth-child(2) {
      opacity: 0;
    }

    .open:nth-child(3) {
      transform: rotate(-45deg) translate(9px, -8px);
    }

    .close:nth-child(1) {
      transform: rotate(0) translateY(0);
    }

    .close:nth-child(2) {
      opacity: 1;
    }

    .close:nth-child(3) {
      transform: rotate(0) translateY(0);
    }
  }

  .one,
  .two,
  .three {
    background-color: #172b4d;
    height: 0.5rem;
    width: 100%;
    margin: 0.6rem auto;
    z-index: 20;
    transition-duration: 0.3s;
  }

  .header {
    display: flex;
    align-items: center;
    z-index: 999;
  }

  .menu-toggle {
    width: 4rem;
    height: 3rem;
  }

  /* fullscreen */
  .menu-section.on {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #f6f9fc;
    z-index: 99;
    display: flex;
    flex-direction: column;
  }

  .menu-section.on .header {
    width: 100%;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-section.on nav {
    display: block;
  }

  .menu-section.on .menu-toggle {
    margin-right: 1.5rem;
  }

  .menu-section.on .menu-toggle .one {
    transform: rotate(45deg) translate(0.7rem, 0.7rem);
  }

  .menu-section.on .menu-toggle .two {
    opacity: 0;
  }

  .menu-section.on .menu-toggle .three {
    transform: rotate(-45deg) translate(0.8rem, -0.9rem);
  }

  .menu-section.on nav ul {
    text-align: center;
    display: block;
  }

  .menu-section.on nav ul a {
    transition-duration: 0.5s;
    font-size: 2rem;
    line-height: 4rem;
    display: block;
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .menu-section.on .header .logo {
    display: block;
  }
}
