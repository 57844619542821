.cl {
  color: #525f7f;
}

.col-img {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.col-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.mb {
  margin-bottom: 3rem;
}

.img {
  height: 30rem;
}

@media (max-width: 600px) {
  .center {
    display: flex;
    flex-direction: column;
  }

  .pad {
    padding: 0 auto;
  }

  .pad-right {
    padding-left: 15px;
  }

  .col-img {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .convert {
    display: flex;
    flex-direction: column-reverse;
  }
}
