.card-position {
  margin-top: -3rem;
}

.title {
  margin-top: 7rem;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.description {
  width: 55%;
  text-align: center;
}

.mb-b {
  margin-bottom: 4rem;
}

.mt {
  margin-top: 16rem;
}

.color {
  color: #525f7f;
}

.card-title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 3.3rem;
  color: #525f7f;
}

.card-desc {
  font-family: Open Sans;
  font-weight: bold;
  font-size: 2.8rem;
  line-height: 4.4rem;
  text-align: center;
  color: #525f7f;
  margin-bottom: 0;
}

.p {
  text-align: center;
  margin-top: -1rem;
  color: #525f7f;
}

.card-title-center {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 3.3rem;
  color: #172b4d;
}

.card-desc-center {
  font-family: Open Sans;
  font-weight: bold;
  font-size: 3.2rem;
  line-height: 4.4rem;
  text-align: center;
  color: #172b4d;
  margin-bottom: 0;
}

.p-center {
  text-align: center;
  margin-top: -1rem;
  color: #172b4d;
}

.card-title-media {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 3.3rem;
  text-align: center;
  color: #172b4d;
}

.card-desc-media {
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 1.6rem;
  color: #172b4d;
  margin-bottom: 2rem;
}

.justify-flex-end {
  justify-content: flex-end;
}

@media (min-width: 600px) and (max-width: 992px) {
  .object-remove {
    display: none;
  }
}

@media (max-width: 600px) {
  .flex-d-col {
    display: flex;
    flex-direction: column;
  }

  .margin-buttom {
    margin-bottom: 3rem;
  }

  .card-position {
    margin-top: -0;
  }

  .text-center-screen {
    text-align: center;
  }

  .justify-center-screen {
    justify-content: center;
  }

  .align-center-screen {
    align-items: center;
  }

  .center {
    text-align: center;
  }

  .column-screen {
    flex-direction: column;
  }

  .image-top {
    width: 100%;
    max-width: 200px;
    margin-top: -40px;
    margin-bottom: -60px;
  }

  .col-screen {
    width: 100%;
  }
}
