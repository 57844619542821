.img-black {
  filter: grayscale(100%);
}

.img-black:hover {
  filter: grayscale(0);
}

.mobile-config {
  @media screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
